import React from "react";
import { Link } from "gatsby";
import { Center, Container, Heading, Code } from "@chakra-ui/react";

import Layout from "../components/Layout";
import Paragraph from "../ui/common/Paragraph";

const NotFoundPage = () => {
  return (
    <Layout>
      <Center>
        <Container maxW="7xl" p="15vh" fontFamily="Noto sans, sans-serif">
          <Heading size="lg" color="gray" marginBottom="1em">
            Page Not found
          </Heading>

          <Paragraph>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn't find what you were looking for.
            <br />
            {process.env.NODE_ENV === "development" ? (
              <>
                <br />
                Try creating a page in <Code>src/pages/</Code>.
                <br />
              </>
            ) : null}
            <br />
            <Link to="/">Go home</Link>.
          </Paragraph>
        </Container>
      </Center>
    </Layout>
  );
};

export default NotFoundPage;
